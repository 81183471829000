body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: #871a02;
}
a {
  text-decoration: none;
}
#appWrapper {
	background-color: black;
	color: gainsboro;
	width: 100vw;
	padding-top: 40px;
}
#app {
	width: 80%;
	margin: 0px auto;
}
#navLinks {
	position: fixed;
	width: 80%;
	z-index: 100;
	margin-top: -80px;
}
#topLink {
	float: right;
	font-size: 40px;
	margin-right: 20px;
	cursor: pointer;
	color: #871a02;
	text-decoration: underline;
}
#contactLink {
	float: right;
	font-size: 40px;
	cursor: pointer;
	color: #871a02;
	text-decoration: underline;
}
#intro {
	display: grid;
	grid-template-columns: 20% 80%;
	height: 350px;
	margin-top: 60px;
	margin-bottom: 100px;
	color: gainsboro;
}
#headshot {
	max-height: 100%;
	max-width: 100%;
}
#name {
	font-size: 100px;
	margin-bottom: 10px;
}
#headline {
	font-size: 40px;
	font-style: italic;
}
#topDivider {
	grid-column: 1/3;
	position: relative;
	width: 100%;
	margin-top: 50px;
	height: 30px;
	background-color: #038423;
	background-image: linear-gradient(#000000, #871a02);
	border-radius: 5px;
	margin-bottom: -30px;
}
#items {
	width: 100%;
	margin-top: -10px;
	margin-bottom: 50px;
}
#itemsMessage {
	font-size: 32px;
	margin-bottom: 10px;
}
#videosMessage {
	font-size: 20px;
	margin-bottom: 80px;
}
.itemWrapper {
	margin-top: 0px;
	margin-bottom: 80px;
}
.itemRight {
	display: grid;
	height: 80vh;
	grid-template-columns: 20% 75%;
  	grid-column-gap: 5%;
  	align-items: center;
  	font-size: 22px;
}
.itemLeft {
	display: grid;
	height: 70vh;
	grid-template-columns: 70% 25%;
  	grid-column-gap: 5%;
  	align-items: center;
}
.mediaRight {
	height: 100%;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
}
.mediaLeft {
	height: 100%;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
}
.itemTitle {
	font-size: 30px;
	margin-bottom: 10px;
}
#itemDate {
	margin-top: 15px;
	font-size: 22px;
	font-style: italic;
}
.vid {
	max-height: 100%;
	max-width: 100%;
	position: relative;
	top: 50%;
	transform: translateY(-50%); 
}
#bottomDivider {
	width: 100%;
	margin-top: 90px;
	margin-bottom: 20px;
	position: relative;
	height: 30px;
	background-color: #038423;
	background-image: linear-gradient(#871a02, #000000);
	border-radius: 5px;
}
.contactLink {
	font-size: 50px;
	display: inline-block;
	cursor: pointer;
	text-decoration: none;
}
#contactMessage {
	margin-top: 50px;
	font-size: 50px;
}
#email {
	margin-bottom: 70px;
	color: #bc7a00;
	text-decoration: underline;
}
#gitHub {
	margin-bottom: 70px;
	color: #8fcc00;
	text-decoration: underline;
}
#linkedIn {
	margin-bottom: 70px;
	color: #01415f;
	text-decoration: underline;
}
#endSpace {
	height: 150px;
}
/**************
MOBILE STYLING
**************/
@media (min-width: 300px) and (max-width: 599px) {
	#intro {
		display: grid;
		grid-template-columns: 100%;
		grid-template-rows: 50% 50%;
		height: 450px;
		margin-top: 60px;
	}
	#headshot {
		grid-row: 1;
		max-height: 100%;
		max-width: 100%;
	}
	#introText {
		grid-row: 2;
		grid-column: 1;
		font-size: 18px;
		font-style: italic;
		margin-top: 10px;
	}
	#topDivider {
		grid-column: 1/3;
		position: relative;
		width: 100%;
		margin-top: 20px;
		margin-bottom: 50px;
		height: 30px;
		background-color: #038423;
		background-image: linear-gradient(#000000, #871a02);
		border-radius: 5px;
		margin-bottom: -30px;
	}
	#topLink {
		float: right;
		font-size: 30px;
		margin-right: 20px;
		cursor: pointer;
		color: #871a02;
		text-decoration: underline;
	}
	#contactLink {
		float: right;
		font-size: 30px;
		cursor: pointer;
		color: #871a02;
		text-decoration: underline;
	}
	#name {
		font-size: 60px;
		margin-bottom: 10px;
	}
	#headline {
		font-size: 22px;
		font-style: italic;
	}
	#items {
		width: 100%;
	}
	.itemWrapper {
		margin-top: 125px;
	}
	#itemsMessage {
		font-size: 20px;
		margin-bottom: 10px;
	}
	#videosMessage {
		font-size: 14px;
		margin-bottom: 80px;
	}
	.itemRight {
		display: grid;
		width: 100%;
		max-height: 450px;
		grid-template-columns: 100%;
		grid-template-rows: 30% 70%;
	  	align-items: center;
	}
	.itemLeft {
		display: grid;
		width: 100%;
		max-height: 450px;
		grid-template-columns: 100%;
		grid-template-rows: 30% 70%;
	  	align-items: center;
	}
	.mediaRight {
		height: 100%;
	    margin-top: 0px;
	    margin-right: auto;
	    margin-bottom: 0px;
	    margin-top: 75px;
	}
	.mediaLeft {
		grid-row: 2;
		height: 100%;
	    margin-top: 75px;
	    margin-right: auto;
	    margin-bottom: 0px;
	    margin-left: auto;
	}
	.itemTitle {
		font-size: 22px;
		margin-bottom: 10px;
		margin-top: 20px;
	}
	.vid {
		max-height: 100%;
		max-width: 100%; 
	}
	#bottomDivider {
		width: 100%;
		margin-top: 90px;
		margin-bottom: 20px;
		position: relative;
		height: 30px;
		background-color: #038423;
		background-image: linear-gradient(#871a02, #000000);
		border-radius: 5px;
	}
	.contactLink {
		font-size: 36px;
		display: inline-block;
		margin-right: 20px;
		cursor: pointer;
		text-decoration: none;
	}
	#contactMessage {
		margin-top: 50px;
		font-size: 36px;
	}
	#email {
		margin-bottom: 70px;
		color: #bc7a00;
		text-decoration: underline;
	}
	#gitHub {
		margin-bottom: 70px;
		color: #8fcc00;
		text-decoration: underline;
	}
	#linkedIn {
		margin-bottom: 70px;
		color: #01415f;
		text-decoration: underline;
	}
}


